<template>
  <v-card class="pa-3 mt-2 mx-auto" color="black lighten-1">
      <v-sparkline
        :labels="labels"
        :value="value"
        :gradient="['#FFA000']"
        line-width="2"
        padding="12"
        stroke-linecap="round"
        smooth
        color="white"
      ></v-sparkline>

    <v-card-text class="pt-0 text-center">
      <div class="subheading font-weight-light white--text mt-6">
        NIFS ranking in Webometrics ranking (First edition in each year) 
      </div>
      <span class="caption font-weight-medium pink--text"
        >*lower the better</span>
      <!-- <v-divider class="my-2 white"></v-divider>
      <v-icon class="mr-2" small color="white">
        mdi-clock
      </v-icon>
      <span class="caption white--text font-weight-light"
        >last update 26 minutes ago</span
      > -->
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    labels: ['2015','2016','2017','2018','2019','2020','2021'],
    value: [21,25,37,37,14,15,17],

  }),
};
</script>
