<template>
<v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2 class="font-weight-regular text-center">Resources for NIFS Staff</h2>
      </v-col>

      <v-col sm="12" md="6" lg="6" xl="4">
        <v-card>
          <v-img height="320px" src="../assets/google_workplace.jpg"> </v-img>
          <v-card-title class="my-2">Google Workplace for NIFS</v-card-title>
          <v-card-subtitle>
            You cann access your Google workplace suite including emails, docs
            and cloud storage through the following link.
          </v-card-subtitle>

          <v-card-actions class="pt-0">
            <v-btn
              text
              color="blue accent-4"
              href="https://drive.google.com/a/nifs.ac.lk"
              target="_blank"
            >
              Access Now
            </v-btn>
            <v-btn icon color="green">
              <v-icon>mdi-lock-open</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col sm="12" md="6" lg="6" xl="4">
        <v-card>
          <v-img height="320px" src="../assets/vistas.jpg"> </v-img>
          <v-card-title class="my-2"
            >Our contribution to Vistas of Prosperity and Splendour</v-card-title
          >
          <v-card-subtitle>
            View the progress and the contrubution towards the Vistas of
            Prosperity and Splendour by the NIFS for the given data range.
          </v-card-subtitle>

          <v-card-actions class="pt-0">
            <v-btn
              text
              color="blue accent-4"
              href="https://vistas-of-prosperity.web.app/"
              target="_blank"
            >
              View Contribution
            </v-btn>
            <v-btn icon color="green">
              <v-icon>mdi-lock-open</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col sm="12" md="6" lg="6" xl="4">
        <v-card class="mx-auto">
          <v-img height="320px" src="../assets/repo.jpg"> </v-img>
          <v-card-title class="my-2"
            >NIFS Research Information Repository</v-card-title
          >
          <v-card-subtitle>
            The NIFS Research Information Repository includes all the research
            and dissemination outputs of the NIFS with real-time insights and reports.
          </v-card-subtitle>

          <v-card-actions class="pt-0">
            <v-btn
              text
              color="blue accent-4"
              href="https://mynifs.nifs.ac.lk/repo"
              target="_blank"
            >
              Access Now
            </v-btn>
            <v-btn icon color="red">
              <v-icon>mdi-lock</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
  
      <v-col sm="12" md="6" lg="6" xl="4">
        <v-card>
          <v-img height="320px" src="../assets/library.jpg"> </v-img>
          <v-card-title class="my-2">NIFS E-Library</v-card-title>
          <v-card-subtitle>
            View the available E-Library resources and facilities. Some services required
            you to access through the NIFS network. 
          </v-card-subtitle>

          <v-card-actions class="pt-0">
            <v-btn
              text
              color="blue accent-4"
              href="https://www.research4life.org"
              target="_blank"
            >
              Access Now
            </v-btn>
            <v-btn icon color="red">
              <v-icon>mdi-lock</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col sm="12" md="6" lg="6" xl="4">
        <v-card>
          <v-img height="320px" src="../assets/docs.jpg"> </v-img>
          <v-card-title class="my-2"
            >Documents and Shared Resources for NIFS Employees</v-card-title
          >
          <v-card-subtitle>
            View and download all the files and documents commonly shared with NIFS employees. Please note that some documents requires additional permission. 
          </v-card-subtitle>

          <v-card-actions class="pt-0">
            <v-btn
              text
              color="blue accent-4"
              href="https://drive.google.com/drive/folders/0AEma33Z6v5VHUk9PVA"
              target="_blank"
            >
              Access Now
            </v-btn>
            <v-btn icon color="green">
              <v-icon>mdi-lock-open</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col sm="12" md="6" lg="6" xl="4">
        <v-card>
          <v-img height="320px" src="../assets/grants.jpg"> </v-img>
          <v-card-title class="my-2">Available Grants and Scholarships</v-card-title>
          <v-card-subtitle>
            View current grants and scholarship oppotunities which are currently
            available you to apply.
          </v-card-subtitle>

          <v-card-actions class="pt-0">
            <v-btn
              text
              color="blue accent-4"
              href="https://www.nifs.ac.lk/research/funding"
              target="_blank"
            >
              View All
            </v-btn>
            <v-btn icon color="green">
              <v-icon>mdi-lock-open</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    
}
</script>