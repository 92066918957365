<template>
  <v-app v-if="isSignIn">
    <navbar :imageURL="imageURL" />
    <v-container fluid>
      <v-content>
        <router-view></router-view>
      </v-content>
    </v-container>
  </v-app>
  <v-app v-else>
    <v-container fill-height fluid grey>
      <v-row justify="center" align="center">
        <v-col sm="5" md="6" lg="3" xl="2">
          <v-card elevation="6">
            <v-img src="../src/assets/nifs_login.jpg"></v-img>
            <v-card-text>
              <h2 class="font-weight-regular my-2">
                myNIFS - Employee portal for NIFS
              </h2>
              <p class="mt-3">Version 1.0.0 beta</p>
            </v-card-text>
            <v-card-actions>
              <div
                id="google-signin-button"
                class="g-signin2 ma-2 mb-4"
                data-onsuccess="onSignIn"
              ></div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col>
        <v-dialog v-model="dialog" hide-overlay persistent width="400">
          <v-card color="red" dark>
            <v-card-text>
              You're not authorized to access the resources. Please try again
              with your institutional email.
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-app>
</template>

<script src="https://apis.google.com/js/platform.js"></script>

<script>
import Navbar from "./components/Navbar";

export default {
  name: "App",
  components: {
    Navbar,
  },

  data() {
    return {
      isSignIn: false,
      imageURL: "",
      dialog: false,
    };
  },

  mounted() {
    window.gapi.load("auth2", () => {
      window.gapi.signin2.render("google-signin-button", {
        onsuccess: this.onSignIn,
      });
    });
  },

  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => ((this.dialog = false), signOut()), 4000);
    },
  },

  methods: {
    signOut() {
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function() {
        window.location = "https://mynifs.nifs.ac.lk";
      });
    },
    onSignIn(user) {
      const profile = user.getBasicProfile();
      var id_token = user.getAuthResponse().id_token;
      var profileURL = profile.getImageUrl();
      this.imageURL = profileURL;
      var email = profile.getEmail();
      console.log(email);
      if (email.includes("@nifs.ac.lk") && id_token != "") {
        this.isSignIn = true;
      } else {
        this.isSignIn = false;
        this.dialog = true;
      }
    },
  },
};
</script>
