<template>
  <nav>
    <v-card color="grey lighten-4" flat tile>
      <v-toolbar dark :color="primary_color">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>
          <v-img
            max-height="60"
            max-width="90"
            src="../assets/nifs_logo.png"
          ></v-img>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-switch 
          v-model="dark_mode"
          label="Dark Mode"
          color="amber darken-3"
          @change="turnDark"
          class="mt-5 hidden-md-and-down"
        ></v-switch>
        <v-badge
          bordered
          top
          :color="notifications"
          :content="messages"
          :value="messages"
          overalap
          offset-x="20"
          offset-y="20"
          class="hidden-md-and-down"
        >
          <v-btn icon>
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </v-badge>

        <v-badge
          bordered
          bottom
          :color="status"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-avatar size="40">
            <v-img :src="image"></v-img>
          </v-avatar>
        </v-badge>
        <!-- <v-avatar>
            <img class="pa-1"
                :src= image
                alt="User"
            >
            </v-avatar> -->
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="googleAccount()"
                >My Google Workplace account</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="signOut()"
                >Sign out</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-card>

    <v-navigation-drawer
      app
      v-model="drawer"
      temporary
      :color="secondary_color"
    >
      <v-list :class="text_col">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title" :class="text_col">
              myNIFS
            </v-list-item-title>
            <v-list-item-subtitle :class="text_col">
              Employee portal for NIFS
            </v-list-item-subtitle>
            <v-list-item-subtitle :class="text_col">
              Version 1.0.0 beta
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <div v-for="navLink in navLinks" :key="navLink.title">
          <v-list-item v-if="!navLink.sublinks" :to="navLink.route">
            <v-list-item-title :class="text_col">{{
              navLink.title
            }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else :key="navLink.title" no-action>
            <template v-slot:activator>
              <v-list>
                <v-list-item-content>
                  <v-list-title :class="text_col">{{
                    navLink.title
                  }}</v-list-title>
                </v-list-item-content>
              </v-list>
            </template>

            <v-list-item
              v-for="sub in navLink.sublinks"
              :key="sub.title"
              :to="sub.route"
            >
              <v-list-item-title :class="text_col">{{
                sub.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script src="https://apis.google.com/js/platform.js"></script>

<script>
export default {
  props: ["imageURL"],
  data() {
    return {
      messages: 0,
      show: false,
      image: this.imageURL,
      status: "green",
      notifications: "red",
      primary_color: "primary",
      secondary_color: "white",
      text_col: "black--text",
      dark_mode: false,
      drawer: false,
      navLinks: [
        { title: "Dashboard", route: "/" },
        {
          title: "Conference Facilities",
          route: "/facility",
          sublinks: [{ title: "View Bookings", route: "/reserve-facility" }],
        },

        // {
        //   title: "Google Workplace",
        //   sublinks: [
        //     {
        //       title: "Gmail",
        //       route: "/github",
        //       beforeEnter() {
        //         window.location("https://example.com");
        //       },
        //     },
        //     { title: "Add Facilities", route: "/add-facility" },
        //   ],
        // },
        // { title: "Events", route: "/event" },
        // { title: "Grants", route: "/grant" },
        // {
        //   title: "Facilities",
        //   route: "/facility",
        //   sublinks: [
        //     { title: "Reserve Facilities", route: "/reserve-facility" },
        //     { title: "Add Facilities", route: "/add-facility" },
        //   ],
        // },
        // {
        //   title: "Recruitment",
        //   route: "/recruitment",
        //   sublinks: [
        //     { title: "Create a vacancy", route: "/create-recruitment" },
        //     { title: "View Applications", route: "/view-applicants" },
        //   ],
        // },
        { title: "Resources", route: "/resources" },
        // { title: "Notifications", route: "/notifications" },
        // { title: "Users", route: "/user" },
      ],
    };
  },
  methods: {
    googleAccount() {
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function() {
        window.location = "https://myaccount.google.com";
      });
    },
    signOut() {
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function() {
        window.location = "https://mynifs.nifs.ac.lk";
      });
    },
    turnDark() {
      if (this.dark_mode) {
        this.primary_color = "black";
        this.secondary_color = "black";
        this.text_col = "white--text";
      } else {
        this.primary_color = "primary";
        this.secondary_color = "white";
        this.text_col = "black--text";
      }
    },
    checkConnection() {
      if (navigator.onLine) {
        this.status = "green";
      } else {
        this.status = "red";
      }
    },
  },
};
</script>
