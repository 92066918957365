<template>
  <v-card class="pa-3 mt-2 mx-auto" color="black lighten-1">
    <v-sparkline
      :labels="labels"
      :value="value"
      :gradient="['#f72047', '#ffd200', '#1feaea']"
      line-width="1"
      padding="12"
      stroke-linecap="round"
      smooth
      color="white"
    ></v-sparkline>

    <v-card-text class="pt-0 text-center">
      <div class="subheading font-weight-light white--text mt-6">
        NIFS Publications in SCOPUS indexed journals as at 12 Oct 2021
      </div>
      <span class="caption font-weight-medium pink--text"
        >*higher the better</span
      >
      <!-- <v-divider class="my-2 white"></v-divider>
      <v-icon class="mr-2" small color="white">
        mdi-clock
      </v-icon>
      <span class="caption white--text font-weight-light"
        >last update 26 minutes ago</span
      > -->
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    // labels: ["2010", "2011", "2012", "2013","2014",'2015','2016','2017','2018','2019','2020','2021'],
    // value: [15,25,32,37,50,53,69,79,69,74,62,72],
    labels: [
      "2010",
      "2011",
      "2012",
      "2013",
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
    ],
    value: [15, 25, 32, 37, 50, 53, 69, 79, 69, 74, 62, 72],
  }),
};
</script>
