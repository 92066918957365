<template>
  <v-container >
    <v-row justify="center" class="hidden-sm-and-down">
        <v-col sm="12" md="6" lg="6">
        <publication-chart />
      </v-col>
      <v-col sm="12" md="6" lg="6">
        <ranking-chart />
      </v-col>
      <!-- <v-col sm="6" md="4" lg="4">
        <v-card
          class="pa-6 mt-4 mx-auto"
          elevation="8"
          max-width="calc(100% - 32px)"
          color="black"
        >
          <v-card-text>
            <div class="amber--text">Webometrics Ranking</div>
            <p class="display-1 white--text">
              17
            </p>
            <p class="grey--text">2021 July Edition</p>
            <div class="white--text">
              Webometrics is continuously researching for improving the ranking,
              changing or evolving the indicators and the weighting model to
              provide a better classification
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="pink"
              href="https://www.webometrics.info/en/Asia/Sri%20Lanka%20"
              target="_blank"
            >
              Learn More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12">
        <h2 class="font-weight-regular text-center">Notifications</h2>
      </v-col>
      <v-col cols="6">
        <v-alert border="left" color="red" dark dismissible icon="mdi-bell">
          The new Vacancy has been created for Research Asssistants
        </v-alert>
        <v-alert border="left" color="indigo" dark dismissible icon="mdi-bell">
          Your reservasion of Facility 'Conference Room' has been approved.
        </v-alert>
      </v-col> -->

      <!-- <v-col cols="6">
        <v-alert text color="info">
          <h3 class="headline">
            COVID-19 Updates
          </h3>
          <div>
            Please adhere to the guidelines given by the Heal Authorities when
            traverling and working at the NIFS premises.
          </div>

          <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

          <v-row align="center" no-gutters>
            <v-col class="grow">
              Prof. Saman Seneweera, Director/ NIFS
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink">
              <v-btn color="info" outlined>
                Dismiss
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col> -->
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <employee-resources />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmployeeResources from "../components/EmployeeResources.vue";
import PublicationChart from "../components/PublicationChart.vue";
import RankingChart from "../components/RankingChart.vue";

export default {
  components: { PublicationChart, EmployeeResources, RankingChart },
};
</script>
