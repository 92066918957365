import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
  },
  {
    path: "/reserve-facility",
    name: "Reserve Facility",
    component: () => import("../views/ReserveFacility.vue"),
  },
  {
    path: "/create-recruitment",
    name: "Create Recruitment",
    component: () => import("../views/Recruitment.vue"),
  },

  {
    path: "/view-applicants",
    name: "View Applicants",
    component: () => import("../views/Applicants.vue"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
  },
  {
    path: "/event",
    name: "Event",
    component: () => import("../views/Event.vue"),
  },
  {
    path: "/user",
    name: "User",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("../views/Resources.vue"),
  },
  {
    path: "/add-facility",
    name: "Add Facility",
    component: () => import("../views/AddFacility.vue"),
  },
  {
    path: "/grant",
    name: "Grant",
    
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Grant.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
